




import { Component, Vue } from 'vue-property-decorator'
import { Logs } from '@/api'

@Component({
  components: {
    LogsTable: () => import('@/components/Logs/LogsTable.vue'),
  }
})
export default class AccountTransferLogs extends Vue {
  data = []

  async mounted() {
    this.data = await Logs.getCharacterTransfersLogs()
  }
}
